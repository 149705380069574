<template>
    <div class="login_container">
        <div class="login_box">
            <!-- <div class="avatar_box">
                <img src="../assets/logo.png" alt="" />
            </div> -->
            <div class="login_title">信息流管理后台</div>
               
            
                    <div class="login_tabs">
                    <!--引用对象，loginFormRef表单的实力对象-->
                    <el-form ref="loginForm2Ref" :model="loginForm2" :rules="loginForm2Rules"   label-width="0px" class="login_form">
                        <el-form-item prop="username">
                            <el-input v-model="loginForm2.username" placeholder="请输入用户名" prefix-icon="iconfont icon-xingmingyonghumingnicheng"></el-input>
                            
                        </el-form-item>
                    
                        <el-form-item prop="password">
                            <el-input v-model="loginForm2.password" placeholder="请输入密码" prefix-icon="iconfont icon-mima" type="password"></el-input>
                        </el-form-item>

                        

                        <el-form-item class="btns">
                            <el-button type="success" @click="login2" style="width: 360px">登录</el-button>
                            <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
                        </el-form-item>
                    </el-form>
                    </div>
            
            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            //这是登录表单的数据绑定对象
            loginForm1:{
                phone:'',
                password:''
            },
            loginForm2:{
                username:'',
                password:''
            },
            //验证规则
            loginForm1Rules:{
                phone:[
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],

                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
                ]
            },
            loginForm2Rules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
                ],

                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
                ]
            },

            activeTab:'tab2'  //登录tab
        };
    },
    methods:{
        //清空表单
    //    resetLoginForm1(){
    //        //console.log(this)
    //        this.$refs.loginForm1Ref.resetFields();
    //    },
       
       login2(){//超级管理员+系统管理员登录
           this.$refs.loginForm2Ref.validate(async valid=>{
               //console.log(valid)
               if(!valid) return;
               const {data:res} = await this.$http.post("sys/login",this.loginForm2);
                console.log("登录成功之前");
               console.log(res);//如果某个方法返回promise，可以用 await async 简化 
               if(res.code !== 200) return this.$message.error(res.message);
               
               //将登录成功之后的token，保存到客户端的sessionStroage中
               //项目中除了登录之外的其他api接口，必须在登录之后才能访问
               //token只应在当前网站打开期间生效，所以token保存在sessionStroage中
               console.log("登录成功之后");
               console.log('登录页面的token'+res.result.token);
               window.sessionStorage.setItem('token',res.result.token);//浏览器工具 application -- Storage --  sessionStorage
               window.sessionStorage.setItem('uid',res.result.userInfo.id);
               window.sessionStorage.setItem('userInfo',JSON.stringify(res.result.userInfo));
               //通过编程式导航跳转到后台主页，路由地址是 ／home
               const {data:res2} = await this.$http.get("sys/role/queryOwer");
               if(res2.code !== 200) return this.$message.error(res.message);
               console.log(res2);
               window.sessionStorage.setItem('roles',JSON.stringify(res2.result));
               
               const {data:res3} = await this.$http.get('sys/permission/getUserPermissionByToken?token='+res.result.token);
               console.log("res3");
               console.log(res3);
               if(res3.code !== 200) return this.$message.error(res3.message);
               window.sessionStorage.setItem('menulist',JSON.stringify(res3.result.menu));
               window.sessionStorage.setItem('auth',JSON.stringify(res3.result.auth));

               if(JSON.stringify(res2.result).indexOf("admin")>0){
                   console.log("走welcome了");
                   this.$router.push('/Welcome');
               }else{
                   console.log("走dashboard了");
                   this.$router.push('/dashboard');
               }
               //res2.result.
               this.$message.success("登录成功");
               

           })
       },

        //切换登录tab
       handleClickTab(tab, event) {
        console.log(tab, event);
      }
    }

};//分号一定要写
</script>  

<style lang="less" scoped>
//需要安装less loader 以及 less
.login_container{
    // background-color: #2b4b6b;
    height: 100%;
    background-image: url('../assets/images/login/bj.jpg');
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
}
.login_box{
    width: 400px;
    height: 480px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;//绝对定位
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);//位移 自身的50%

    //less 语法嵌套
    .avatar_box{
        height: 100px;
        width: 100px;
        border: 1px solid #eee;
        border-radius: 50%;//圆形边框
        padding:10px;//内边距
        box-shadow: 0 0 10px #ddd;//阴影 扩散10像素
        position: absolute;//绝对定位
        left:50%;
        transform: translate(-50%,-50%);//位移 自身的50%
        background-color: #fff;//移动上去后透明的不好看
        img{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: #eee;
        }
    }
    //less 语法嵌套
    .login_title{
        position: absolute;//绝对定位
        top:15%;
        left:50%;
        transform: translate(-50%,-50%);//位移 自身的50%
        font-size: 24px;
        letter-spacing:2px;
        font-weight:bold;
        width: 300px;
        text-align: center;
    }
}
.login_tabs{
    position: absolute;
    top: 30%;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;//包括内容，内边距和边框，但不包括外边距
}
.login_form{
    padding-top: 20px;
}

.btns{
    width: 100%;
    display: flex;
    //justify-content: flex-end;
    justify-content:center;
}
/deep/ .el-button--success{
    background-color: #001529;
    border-color: #001529;
}
.forgot_password{
    width: 100%;
    display: flex;
    //justify-content: flex-end;
    justify-content:flex-end;
}
</style>